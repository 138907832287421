
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/projectinfo";
import File from "@/api/file";
import { compress } from "@/utils/compress";

@Options({})
export default class extends Vue {
  private show = false;
  private faceUrl = "";
  private uploader: any[] = [];
  protected goBack() {
    this.$router.go(-1);
  }
  private userId = "";
  private userInfo = { safeFile: [], idNo: "", idCheck: 0 };

  mounted() {
    this.getUserDetail();
  }

  get safeFileComputed() {
    this.userInfo.safeFile.map((li: any) => {
      li.file = {
        name: li.originalName,
      };
    });
    return this.userInfo.safeFile;
  }

  protected clickPreview(file: any) {
    if (
      !/\.(png|jpg|gif|jpeg|webp)$/i.test(
        file.url.substr(file.url.lastIndexOf("."))
      )
    ) {
      window.open(file.url);
    }
  }

  getUserDetail() {
    this.userId = this.$route.query.userId as string;
    api
      .mobilepersondetail({
        projectId: this.$store.state.project.projectinfo.projectId,
        userId: this.userId,
      })
      .then((res) => {
        this.userInfo = res.data;
      });
  }

  protected compare() {
    this.show = true;
  }

  async afterRead(files: any) {
    let file = files.file;
    // 压缩照片
    while (file.size / 1024 > 200) {
      file = await compress(file);
    }

    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "photographmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        this.faceUrl = res.data.url;
      });
    });
  }

  protected openPopup() {
    this.uploader = [];
  }

  protected takePicture() {
    let upload = document.getElementsByClassName("van-uploader__input")[0];
    (upload as any).click();
  }

  protected submit() {
    if (this.uploader.length === 0) {
      this.$toast.fail("请上传图片");
      return;
    }
    // 人脸验证接口
    api
      .photoCompare({
        projectId: this.$store.state.project.projectinfo.projectId,
        imgUrl: this.faceUrl,
        idNo: this.userInfo.idNo,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.userInfo.idCheck = 1;
          this.$toast.success(res.msg);
        } else {
          this.$toast.fail(res.msg);
        }
        this.show = false;
      });
  }
  protected syncPersonRowFun() {
    this.$router.push({
      path: "/app/syncPersonRowFun",
      query: { userId: this.userId },
    });
  }
}
